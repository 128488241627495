<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <validation-observer
      :ref="`${form.id}-form`"
      v-slot="{ invalid }"
      v-if="canEdit"
    >
      <b-row align-v="end">
        <b-col cols="6">
          <div class="form-group">
            <label>Nome do produto</label>
            <validation-provider
              name="medicamento"
              :rules="{ required: true }"
              v-slot="{ errors, touched }"
            >
              <multiselect
                v-if="!fields.custom"
                v-model="fields.produto"
                :options="products"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :internal-search="false"
                @search-change="searchProducts"
                @select="selectProducts"
                placeholder="Pesquisar produto"
                class="search-mode with-border"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option }}
                </template>
                <template slot="caret">
                  <div class="search">
                    <Search />
                  </div>
                </template>
                <template slot="noOptions">
                  Digite para pesquisar um produto
                </template>
                <template slot="noResult" slot-scope="props">
                  <li @click="setCustom(true, props.search)">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      Adicionar produto "{{ props.search }}"
                    </div>
                  </li>
                </template>
              </multiselect>
              <div class="custom-input" v-else>
                <Close class="close-svg" @click="setCustom(false)" />
                <input
                  autocomplete="off"
                  v-model="fields.produto"
                  :readonly="!canEdit"
                  type="text"
                  class="form-control"
                  placeholder=""
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                />
              </div>
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="form-group">
            <label for="quantidade">Quantidade</label>
            <validation-provider
              name="quantidade"
              :rules="{ required: true }"
              v-slot="{ errors, touched }"
            >
              <multiselect
                v-model="fields.quantidade"
                id="quantidade"
                :options="Array.from({ length: 10 }, (_, i) => i + 1)"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :internal-search="true"
                :allowEmpty="false"
                placeholder="Selecionar"
                class="with-border"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option }}
                </template>
                <template slot="noResult">
                  <div>Nenhuma informação encontrada...</div>
                </template>
              </multiselect>
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="2" class="div-button">
          <b-button
            variant="primary h-48 button"
            block
            :disabled="invalid"
            @click="addItem"
          >
            {{ editingIndex !== null ?  'Salvar' : 'Adicionar' }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>

    <table class="table-eyecare with-laterality">
      <thead>
        <tr>
          <th>Nome do produto</th>
          <th>Quantidade</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) of form.value" :key="index">
          <td>{{ value.produto }}</td>
          <td>{{ value.quantidade }}</td>
          <td class="text-right">
            <div class="more-with-menu">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  variant="link"
                  @click="editItem(value, index)"
                  :disabled="!canEdit"
                >
                  Editar
                </b-button>
                <b-button
                  variant="link remove mb-0"
                  :disabled="!canEdit"
                  @click="removeItem(index)"
                >
                  Remover
                </b-button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getCurrentClinic } from '@/utils/localStorageManager'
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import Close from '@/assets/icons/close.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    FormWrapper, Search, Plus,
    Close, ChevronDown, MoreVertical
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.produtosUtilizados,
    })
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      editingIndex: null,
      products: [],
      fields: this.getDefaultFields(),
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/produtosUtilizados', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        custom: null,
        produto: null,
        quantidade: null
      }
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.produto = value ? searchTerm : null
    },
    selectProducts() {
      this.products = []
    },
    async searchProducts(query) {
      if (query.length > 2) {
        this.products = []
        try {
          await this.getProducts(query)
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async getProducts(query) {
      try {
        const response = await this.api.getProducts(this.clinic.id, null, query)
        const products = response.data.data.map(data => {
          return data.name
        })

        this.products = products.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
        }, Object.create(null))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    addItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.table-eyecare {
  padding: 16px;
  &.with-laterality {
      tr {
        th {
          width: 25%;
          max-width: 25%;
        }
      }
    }
}
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 24px;
}
</style>
